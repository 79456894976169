div.dataTables_wrapper {
    div.dataTables_length {
        label {
            font-size: 0.875rem;
            margin-bottom: 0;
        }

        select {
            width: 60px;
        }
    }

    div.dataTables_filter {
        label {
            font-size: 0.875rem;
            margin-bottom: 0;
        }
    }

    div.dataTables_info {
        font-size: 0.875rem;
        padding-top: 0;
    }

    div.dataTables_paginate {
        ul.pagination {
            margin: 0;

            .page-item.active {
                .page-link {
                    border-color: #003762;
                    background-color: #003762;

                    &:hover {
                        color: #ffffff;
                        border-color: darken(#003762, 5%);
                        background-color: darken(#003762, 5%);
                    }
                }
            }
        }
    }
}

.dataTables_wrapper {
    > .row {
        &:first-child {
            margin-bottom: 1rem !important;
        }

        &:last-child {
            margin-top: 2rem !important;
        }
    }
}

.table thead th {
    border-bottom: 2px solid #dee2e6;
}

tr.odd td {
    background: #F2F2F2;
}

table.dataTable thead th, table.dataTable thead td {
    height: auto;
}

.checkbox-group {
    > div {
        flex: 1;
        flex-basis: 50%;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

#extended-search > .form-group:last-child {
    margin-bottom: 0;
}

#extendedSearchBody,
#customizeColumnBody {
    border-top: 1px solid #dee2e6;
}

#extended-search {
    .ui-spinner,
    .multiselect-native-select {
        flex: 1;
        width: calc(100% - 45px);
    }

    .input-group {
        flex-wrap: nowrap;
    }
}

.dtr-details li {
    white-space: normal;
}

@media (max-width: 767px) {
    .dataTables_length {
        margin-bottom: 12px;
    }

    .dataTables_filter {
        margin-bottom: 24px;
    }

    .dataTables_info {
        margin-top: 24px;
        margin-bottom: 12px;
    }

    .dataTables_paginate {
        margin-bottom: 24px !important;
    }
}
