.ui-spinner {
    display: block;

    &.ui-widget-content {
        border: none;
    }

    input {
        font-family: "roboto", sans-serif;
        font-size: $input-font-size;
        margin: 0;
        padding-right: 26px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .ui-button {
        border: none;
        background-color: #003762;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
            background-color: darken(#003762, 5%);
        }
    }
}

.ui-icon,
.ui-widget-content .ui-icon {
    background-image: url("../../images/jquery-ui/ui-icons_ffffff_256x240.png");
}
.ui-widget-header .ui-icon {
    background-image: url("../../images/jquery-ui/ui-icons_ffffff_256x240.png");
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
    background-image: url("../../images/jquery-ui/ui-icons_ffffff_256x240.png");
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
    background-image: url("../../images/jquery-ui/ui-icons_ffffff_256x240.png");
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
    background-image: url("../../images/jquery-ui/ui-icons_ffffff_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
    background-image: url("../../images/jquery-ui/ui-icons_ffffff_256x240.png");
}
.ui-button .ui-icon {
    background-image: url("../../images/jquery-ui/ui-icons_ffffff_256x240.png");
}
