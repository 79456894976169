.multiselect {
    border: 0;
    background-color: #ffffff;
}

.multiselect-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    max-height: 50vh !important;
    padding: 0 0 .5rem 0;
    margin: 0;
    box-sizing: content-box;
    box-shadow: 0 0 12px rgba(58, 59, 69, 0.15);
    z-index: 9999;

    > li > a > label {
        display: flex !important;
        align-items: center;
        padding: .5rem 1rem;
        line-height: 1.5;

        > input {
            margin-right: 1rem;
            margin-bottom: 0 !important;
        }
    }

    > li:not(.multiselect-filter) {
        transition: background-color .3s ease;

        > a {
            display: block;
        }

        &:hover {
            background-color: #F3F3F3;
        }
    }

    .multiselect-filter {
        margin-bottom: .5rem;

        span {
            background-color: map-get($theme-colors, "primary");
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;

            i {
                font-size: 1rem;
                color: white;
            }
        }
    }

    .multiselect-search {
        border-top: 0;
        border-right: 0;
        border-left: 0;
    }
}
