/* Forms */

.input-group-append .btn {
    z-index: 1;
}

.input-group-append,
.input-group-prepend {
    background: $input-bg;
    color: $input-placeholder-color;
    width: auto;
    border: none;

    .input-group-text {
        background: transparent;
        border-color: $border-color;
    }
}

.input-group {
    > .multiselect-native-select {
        flex: 1;
    }
}

.form-control {
    display: inline-block;
    border: 1px solid $border-color;
    font-family: $type-1;
    font-size: $input-font-size;
    color: $body-color;
    padding: 0 .75rem;
    line-height: 14px;
    font-weight: 300;

    &.form-control-lg {
        padding: $input-btn-padding-y-lg .75rem;
    }

    &.form-control-sm {
        padding: $input-btn-padding-y-sm .75rem;
    }
}

textarea.form-control {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

select {
    &.form-control {
        padding: 0.4375rem 0.75rem;
    }
}

.form-group {
    label {
        font-size: $default-font-size;
        line-height: 1;
        vertical-align: top;
    }

    &.has-danger {
        .form-control {
            border-color: $danger;
        }
    }

    .file-upload-default {
        visibility: hidden;
        position: absolute;
    }

    .file-upload-info {
        background: transparent;

        a {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.dict-group {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 1rem;
    margin-top: 1rem;

    > .form-group:last-child {
        margin-bottom: 0 !important;
    }
}

.formEditContainer:empty {
    display: none;
}

.upload-placeholder {
    background-color: #F2F2F2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 200px;
    margin: 0 auto;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    &--xl {
        height: 250px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
