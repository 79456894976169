/*-------------------------------------------------------------------*/

/* === Import Bootstrap functions and variables === */

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
/*-------------------------------------------------------------------*/

/* === Import template variables === */

@import "variables";
/*-------------------------------------------------------------------*/

/* === Boostrap Main SCSS === */

@import "../../../../node_modules/bootstrap/scss/bootstrap";
/*-------------------------------------------------------------------*/

/* === Template mixins === */

@import "mixins/animation";
@import "mixins/background";
@import "mixins/blockqoute";
@import "mixins/buttons";
@import "mixins/cards";
@import "mixins/misc";
@import "mixins/text";
@import "mixins/loading";
/*-------------------------------------------------------------------*/

/* === Core Styles === */

@import "reset";
@import "fonts";
@import "functions";
@import "typography";
@import "misc";
@import "utilities";
/*-------------------------------------------------------------------*/

/* === Components === */

@import "components/bootstrap-progress";
@import "components/buttons";
@import "components/cards";
@import "components/checkbox-radio";
@import "components/dropdown";
@import "components/forms";
@import "components/icons";
@import "components/lists";
@import "components/preview";
@import "components/tables";
@import "components/timeline";
@import "components/toasts";
/*-------------------------------------------------------------------*/

/* === Plugin overrides === */

@import "components/plugin-overrides/jquery-ui";
@import "components/plugin-overrides/jquery.dataTables.yadcf";
@import "components/plugin-overrides/datatables";
@import "components/plugin-overrides/multiselect";
/*-------------------------------------------------------------------*/

/* === Landing screens === */

@import "screens/auth";
@import "screens/td-dashboard";
/*-------------------------------------------------------------------*/
