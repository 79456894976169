.td-dashboard-wrapper {
    &__header {
        margin-bottom: 25px;
    }

    > .row {
        > div {
            margin-bottom: 25px;
        }
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 300px;
        object-fit: cover;

        @media (max-width: 1599px) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 25px;
        }
    }

    .card {
        height: 100%;

        .btn-icons {

        }
    }

    .card-body {
        display: flex;
        flex-direction: column;

        > .row {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 1.81rem;
            margin-bottom: 1.81rem;
            flex: 1;

            @media (max-width: 1599px) {
                flex-direction: column;

                > div {
                    flex: 1;
                    max-width: 100%;
                }
            }
        }
    }

    &__tools {
        display: flex;
        flex-wrap: wrap;
        margin: -14.5px 0;

        > .button-wrapper {
            padding: 14.5px 0;

            &:not(:last-child) {
                margin-right: 1.81rem;
            }
        }
    }

    &__pagination {
        display: flex;
        margin: 20px 0;

        .dashboardPagination {
            border-radius: 0;
            background-color: #ffffff;
            font-size: 0.875rem;
            line-height: 1.25;
            text-decoration: none;
            padding: 0.5rem 0.75rem;
            border: 1px solid #dee2e6;

            &:hover {
                color: #0056b3;
                background-color: #e9ecef;
                border-color: #dee2e6;
            }

            &:first-child {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }

            &:last-child {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }

            &:not(:first-child) {
                margin-left: -1px;
            }

            &.active {
                color: #ffffff;
                border-color: #003762;
                background-color: #003762;

                &:hover {
                    border-color: #002949;
                    background-color: #002949;
                }
            }
        }
    }
}

.tdDashboardSearch-wrap {
    margin-bottom: 35px;

    label {
        font-size: 0.875rem;
    }

    input {
        width: auto;
        margin-left: .5em;
    }
}
