/* Cards */

.card {
  border: none;

  .card-header {
    background: lighten($card-bg, 3%);
    padding: $card-padding-y $card-padding-x;
    border-bottom: 1px solid $border-color;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    small {
      margin-bottom: 0;
    }

    &.header-sm {
      padding: 15px $card-padding-x;
      height: 60px;

      p,
      .dropdown .dropdown-toggle i {
        color: $text-muted;
      }
    }
  }

  .card-body {
    padding: $card-padding-x;

    &.no-gutter {
      padding: 0;
    }

    +.card-body {
      padding-top: 0;
    }
  }

  &.card-rounded {
    border-radius: 5px;
  }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }

  .card-subtitle {
    color: rgba($body-color, 0.7);
  }

  &.card-no-shadow {
    box-shadow: none;
  }
}
